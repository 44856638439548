




// import React from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
//   Dot,
// } from "recharts";
// import Lottie from "lottie-react";
// import animation from "../Images/pulse.json"
// const VideoStatusChart = ({ videoData, footprintName }) => {
//   console.log("video hu", videoData);

//   // Transform data to create ECG-like spikes
//   const transformedData = videoData.flatMap((event) => [
//     { timestamp: event.timestamp, status: 0 }, // Start at 0
//     { timestamp: event.timestamp, status: event.status === "success" ? 1 : -1 }, // Spike to 1 or -1
//     { timestamp: event.timestamp, status: 0 }, // Return to 0
//   ]);


//   const CustomBlinkingDot = (props) => {
//     const { cx, cy, index, payload } = props;
//     const isLast = index === transformedData.length - 1; // Check if it's the last point

//     const fillColor =
//       payload.status === 1 ? "#12B76A" : payload.status === -1 ? "#D92D20" : "gray";

//     const dotSize = payload.status === 0 ? 2 : 4; // Smaller for Neutral (0), Larger for Pass/Fail

//     return isLast ? (
//       <foreignObject x={cx - 12} y={cy - 12} width="28" height="28">
//         <Lottie animationData={animation} loop={true} />
//       </foreignObject>
//     ) : (
//       <circle cx={cx} cy={cy} r={dotSize} fill={fillColor} />
//     );
//   };


//   return (
//     <div style={{ width: "100%" }} className="overflow-x-hidden">
//       <div className="flex justify-between items-center"><h4 className="m-4 text-xl font-semibolds">{footprintName}</h4>


//         <div className="right-section flex justify-between items-center gap-1 mr-4 ">
//           <div className="rounded-full bg-[#D92D20] w-3 h-3"></div>
//           <h4 className=" ">  Live</h4></div>

//       </div>

//       <ResponsiveContainer width="100%" height={200}>
//         <LineChart
//           data={transformedData}
//           syncId="videoChart"
//           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
//         >
//           {/* <CartesianGrid strokeDasharray="3 3" /> */}

//           <XAxis
//             dataKey="timestamp"
//             type="category"
//             // tickFormatter={(timestamp) => {
//             //   const date = new Date(timestamp);
//             //   return !isNaN(date.getTime())
//             //     ? date.toLocaleTimeString([], {
//             //         hour: "2-digit",
//             //         minute: "2-digit",
//             //       })
//             //     : "";
//             // }}

//             tickFormatter={(timestamp) => {
//               return ""

//             }}
//           />


//           <YAxis
//             domain={[-1, 1]}
//             ticks={[-1, 0, 1]}
//             tick={({ x, y, payload }) => {
//               let color = "black"; // Default color
//               let text = payload.value;

//               if (payload.value === 1) {
//                 color = "#12B76A";
//                 text = "Pass";
//               } else if (payload.value === 0) {
//                 color = "gray";
//                 text = "Wait";
//               } else if (payload.value === -1) {
//                 color = "#D92D20";
//                 text = "Fail";
//               }

//               return (
//                 <text x={x - 10} y={y} fill={color} textAnchor="middle" fontSize={14} fontWeight="bold">
//                   {text}
//                 </text>
//               );
//             }}
//           />


//           {/* <Tooltip /> */}

//           <Tooltip
//             formatter={(value) => {
//               let statusText = "Wait"; // Default case

//               if (value === 1) statusText = "Pass";
//               else if (value === -1) statusText = "Fail";

//               return [statusText, "Status"];
//             }}
//           />

//           <Legend />
//           <Line
//             type="linear"
//             dataKey="status"
//             stroke="#8884d8"
//             dot={<CustomBlinkingDot />} // Use the custom dot
//             isAnimationActive={false}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default VideoStatusChart;



//-------------> above is correct without label.
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Lottie from "lottie-react";
import animation from "../Images/pulse.json";

const VideoStatusChart = ({ videoData, footprintName }) => {
  console.log("video hu", videoData);

  // Transform data to create ECG-like spikes
  const transformedData = videoData.flatMap((event) => [
    { timestamp: event.timestamp, status: 0 }, // Start at 0
    { timestamp: event.timestamp, status: event.status === "success" ? 1 : -1 }, // Spike to 1 or -1
    { timestamp: event.timestamp, status: 0 }, // Return to 0
  ]);

  // Custom dot styling
  const CustomBlinkingDot = (props) => {
    const { cx, cy, index, payload } = props;
    const isLast = index === transformedData.length - 1; // Check if it's the last point

    const fillColor =
      payload.status === 1 ? "#12B76A" : payload.status === -1 ? "#D92D20" : "#b9c0d4";

    const dotSize = payload.status === 0 ? 2 : 4; // Smaller for Neutral (0), Larger for Pass/Fail

    return isLast ? (
      <foreignObject x={cx - 12} y={cy - 12} width="28" height="28">
        <Lottie animationData={animation} loop={true} />
      </foreignObject>
    ) : (
      <circle cx={cx} cy={cy} r={dotSize} fill={fillColor} />
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      let statusText = "Wait";

      if (data.status === 1) {
        statusText = "Pass";
      } else if (data.status === -1) {
        statusText = "Fail";
      }

      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
          <p className="label" style={{ margin: 0 }}>{`Status : ${statusText}`}</p>
          <p className="desc" style={{ margin: 0 }}>{`Timestamp: ${data.timestamp}`}</p>
        </div>
      );
    }

    return null;
  };




  // Custom Legend Component
  const CustomLegend = () => {
    return (
      <div className="flex justify-center items-center gap-4 ">
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#12B76A] rounded-full"></div>
          <span className="text-sm font-medium">Pass</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#F04438] rounded-full"></div>
          <span className="text-sm font-medium">Fail</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-[#b9c0d4] rounded-full"></div>
          <span className="text-sm font-medium">Wait</span>
        </div>
      </div>
    );
  };

  return (
    // <div style={{ width: "100%" }} className="overflow-x-hidden">
    //   <div className="flex justify-between items-center">
    //     <h4 className="m-4 text-xl font-semibold">{footprintName}</h4>
    //     <div className="right-section flex justify-between items-center gap-1 mr-4">
    //       <div className="rounded-full bg-[#D92D20] w-3 h-3"></div>
    //       <h4 className="text-sm">Live</h4>
    //     </div>
    //   </div>

    //   <ResponsiveContainer width="100%" height={200}>
    //     <LineChart
    //       data={transformedData}
    //       syncId="videoChart"
    //       margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    //     >
    //       <XAxis
    //         dataKey="timestamp"
    //         type="category"
    //         tickFormatter={() => ""}
    //       />
    //       <YAxis
    //         domain={[-1, 1]}
    //         ticks={[-1, 0, 1]}
    //         tick={({ x, y, payload }) => {
    //           let color = "black";
    //           let text = payload.value;

    //           if (payload.value === 1) {
    //             color = "#12B76A";
    //             text = "Pass";
    //           } else if (payload.value === 0) {
    //             color = "gray";
    //             text = "Wait";
    //           } else if (payload.value === -1) {
    //             color = "#D92D20";
    //             text = "Fail";
    //           }

    //           return (
    //             <text x={x - 14} y={y} fill={color} textAnchor="middle" fontSize={14} fontWeight="bold">
    //               {text}
    //             </text>
    //           );
    //         }}
    //       />

    //       <Tooltip
    //         formatter={(value) => {
    //           let statusText = "Wait";
    //           if (value === 1) statusText = "Pass";
    //           else if (value === -1) statusText = "Fail";
    //           return [statusText, "Status"];
    //         }}
    //       />

    //       <Legend content={<CustomLegend />} /> {/* Use custom legend */}

    //       <Line
    //         type="linear"
    //         dataKey="status"
    //         stroke="#8884d8"
    //         dot={<CustomBlinkingDot />}
    //         isAnimationActive={false}
    //       />
    //     </LineChart>
    //   </ResponsiveContainer>
    // </div>

    <div style={{ width: "100%" }} className="overflow-x-hidden">
      <div className="flex justify-between items-center">
        <h4 className="m-4 text-xl font-semibold">{footprintName}</h4>
        <div className="right-section flex justify-between items-center gap-1 mr-4">
          <div className="rounded-full bg-[#D92D20] w-3 h-3"></div>
          <h4 className="text-sm">Live</h4>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={transformedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }} // Removed syncId
        >
          <XAxis
            dataKey="timestamp"
            type="category"
            tickFormatter={() => ""}
          />
          <YAxis
            domain={[-1, 1]}
            ticks={[-1, 0, 1]}
            tick={({ x, y, payload }) => {
              let color = "black";
              let text = payload.value;

              if (payload.value === 1) {
                color = "#12B76A";
                text = "Pass";
              } else if (payload.value === 0) {
                color = "gray";
                text = "Wait";
              } else if (payload.value === -1) {
                color = "#D92D20";
                text = "Fail";
              }

              return (
                <text x={x - 14} y={y} fill={color} textAnchor="middle" fontSize={14} fontWeight="bold">
                  {text}
                </text>
              );
            }}
          />

          <Tooltip content={<CustomTooltip />} />  {/* Using Custom Tooltip */}

          <Legend content={<CustomLegend />} /> {/* Use custom legend */}

          <Line
            type="linear"
            dataKey="status"
            stroke="#8884d8"
            dot={<CustomBlinkingDot />}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default VideoStatusChart;





// little bit progressive move.

// import React, { useState, useEffect } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import Lottie from "lottie-react";
// import animation from "../Images/pulse.json";

// const VideoStatusChart = ({ videoData, footprintName }) => {
//   console.log("video hu", videoData);

//   // State for transformed data
//   const [transformedData, setTransformedData] = useState([]);

//   useEffect(() => {
//     // Transform data when videoData changes
//     const newData = videoData.flatMap((event) => [
//       { timestamp: event.timestamp, status: 0 },
//       { timestamp: event.timestamp, status: event.status === "success" ? 1 : -1 },
//       { timestamp: event.timestamp, status: 0 },
//     ]);
//     setTransformedData(newData);
//   }, [videoData]);

//   useEffect(() => {
//     if (transformedData.length === 0) return;

//     const interval = setInterval(() => {
//       setTransformedData((prevData) => {
//         if (prevData.length === 0) return prevData;

//         const lastIndex = prevData.length - 1;
//         const lastPoint = prevData[lastIndex];

//         if (lastPoint.status !== 0) {
//           // Move towards neutral (0) step by step
//           const updatedLastPoint = {
//             ...lastPoint,
//             status: lastPoint.status > 0 ? lastPoint.status - 0.1 : lastPoint.status + 0.1,
//           };

//           // Update the data
//           return [...prevData.slice(0, lastIndex), updatedLastPoint];
//         }
//         return prevData;
//       });
//     }, 60000); // Update every minute

//     return () => clearInterval(interval);
//   }, [transformedData]);

//   const CustomBlinkingDot = (props) => {
//     const { cx, cy, index } = props;
//     const isLast = index === transformedData.length - 1;

//     return isLast ? (
//       <foreignObject x={cx - 12} y={cy - 12} width="28" height="28">
//         <Lottie animationData={animation} loop={true} />
//       </foreignObject>
//     ) : (
//       <circle cx={cx} cy={cy} r={2} fill="gray" />
//     );
//   };

//   return (
//     <div style={{ width: "100%" }} className="overflow-x-hidden">
//       <div className="flex justify-between items-center">
//         <h4 className="m-4 text-xl font-semibolds">{footprintName}</h4>
//         <div className="right-section flex justify-between items-center gap-1 mr-4">
//           <div className="rounded-full bg-[#D92D20] w-3 h-3"></div>
//           <h4>Live</h4>
//         </div>
//       </div>

//       <ResponsiveContainer width="100%" height={200}>
//         <LineChart data={transformedData} syncId="videoChart" margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//           <XAxis dataKey="timestamp" type="category" tickFormatter={() => ""} />
//           <YAxis domain={[-1, 1]} ticks={[-1, 0, 1]} tickFormatter={(tick) => (tick === 1 ? "Pass" : tick === 0 ? "Wait" : "Fail")} />
//           <Tooltip />
//           <Legend />
//           <Line type="linear" dataKey="status" stroke="#8884d8" dot={<CustomBlinkingDot />} isAnimationActive={false} />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default VideoStatusChart;
