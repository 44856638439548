import React, { useState, useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import Test_Figures from "./Test_Figures";
import ChartComponent from "../Chart/CharComponent";
import Alert_Card from "../Card/Alert_Card";
import Empty_Screen_Card from "../Card/Empty_Screen_Card";
import monitorImage from "../Images/monitorImage.png"
import RechartsPieChart from "../Chart/RechartsPieChart";
import EcgGraph from "../Chart/EcgGraph";
import ECGGraph from "../Chart/EcgGraph";
import StockGraph from "../Chart/StockGraph";
import RealTimeGraph from "../Chart/RealTimeGraph";
import VideoStatusChart from "../Chart/VideoStatusChart";


const Dashboard = () => {
    const [figures, setFigures] = useState({
        totalActiveCount: "",
        totalCompleteCount: "",
        totalInactiveCount: "",
        totalPausedCount: ""
    });



    const [websiteUrl, setWebsiteUrl] = useState();

    const { state } = useLocation();
    const [footprints, setFootprints] = useState([]);
    const { websiteId } = useParams()

    const fetchMonitoredFootprints = async () => {
        try {
            const response = await axios.get(`${baseUrl}/websites/${websiteId}/monitor`);
            console.log(response.data, "hdid")
            setFootprints(response.data.data);
            setWebsiteUrl(response.data.url);

        } catch (error) {
            console.log(error);
        }

    }

    // const websiteId = localStorage.getItem("selectedWebsiteId");
    console.log('ye nh aaya', websiteId)

    const fetchFootprintCountStatus = async () => {
        try {
            const response = await axios.get(
                `${baseUrl}/websites/${websiteId}/count-status`
            );
            console.log("response from footprint by Id", response.data);
            setFigures(response.data);
        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };

    useEffect(() => {
        if (websiteId) {
            fetchFootprintCountStatus();
            fetchMonitoredFootprints();
        }
    }, [websiteId]);




    // const processFootprintVideos = (footprints) => {
    //     return footprints.map((footprint) => {
    //         return {
    //             footprintId: footprint._id, // Assuming footprints have an _id
    //             footprintName: footprint.name,
    //             videos: footprint.videos.map((video) => {
    //                 const istTime = new Date(video.createdAt).toLocaleString("en-IN", {
    //                     timeZone: "Asia/Kolkata",
    //                 });
    //                 return {
    //                     createdTime: istTime,
    //                     status: video.status,
    //                 };
    //             }),
    //         };
    //     });
    // };


    // Process the data
   
    // const processFootprintVideos = (footprints) => {
    //     return footprints.map((footprint) => {
    //         return {
    //             footprintId: footprint._id, // Assuming footprints have an _id
    //             footprintName: footprint.name,
    //             videos: footprint.videos.map((video) => {
    //                 return {
    //                     createdDate: new Date(video.createdAt).toLocaleDateString("en-IN", {
    //                         timeZone: "Asia/Kolkata",
    //                     }),
    //                     createdTime: new Date(video.createdAt).toLocaleTimeString("en-IN", {
    //                         timeZone: "Asia/Kolkata",
    //                     }),
    //                     status: video.status,
    //                 };
    //             }),
    //         };
    //     });
    // };
    


   






    const processFootprintVideos = (footprints) => {
        return footprints.map((footprint) => {
          // Process each footprint
          return {
            footprintName: footprint.name, // Add footprint name
            videoData: footprint.videos.map((video) => {
              // Convert createdAt to IST timestamp
              const timestamp = new Date(video.createdAt).toLocaleString("en-IN", {
                timeZone: "Asia/Kolkata",
                hour12: false, // Use 24-hour format
              });
      
              return {
                timestamp: timestamp, // IST timestamp
                status: video.status, // Video status
              };
            }),
          };
        });
      };
   
    const formattedData = processFootprintVideos(footprints);

    // Log the output
    console.log(formattedData[0]?.videoData);
    // const videoData = [
    //     {
    //         time: 3.9, result: 0
    //     },
    //     { time: 4, result: 1 },
    //     { time: 4.1, result: 0 },
    //     { time: 11.9, result: 0 },
    //     { time: 12, result: 1 }, // Fail at 12h
    //     { time: 12.1, result: 0 }, // Pass at 20h
    // ];

    // const videoData = [
    //     { videoId: "1", videoCreationTime: 3, videoStatus: "pass" },
    //     { videoId: "2", videoCreationTime: 4, videoStatus: "fail" },
    //     { videoId: "3", videoCreationTime: 5, videoStatus: "pass" },
    //     { videoId: "4", videoCreationTime: 6, videoStatus: "pass" },

    //     { videoId: "5", videoCreationTime: 7, videoStatus: "fail" },
    //     { videoId: "6", videoCreationTime: 8, videoStatus: "fail" },
    //     { videoId: "7", videoCreationTime: 9, videoStatus: "fail" },


    //     { videoId: "8", videoCreationTime: 10, videoStatus: "pass" },
    //     { videoId: "9", videoCreationTime: 11, videoStatus: "fail" },
    //     { videoId: "10", videoCreationTime: 12, videoStatus: "pass" },




    // ];

    // const videoData = [
    //     { id: 1, videoTime: '10:30:15', videoStatus: 'pass' },
    //     { id: 2, videoTime: '10:30:20', videoStatus: 'fail' },
    //   ];

    // const videoData = [
    //     { id: 1, videoTime: '10:41:05 AM', videoStatus: 'pass' }, // Just before current time
    //     { id: 2, videoTime: '10:41:40 AM', videoStatus: 'fail' }, // Around current time
    //     { id: 3, videoTime: '2:20:50 PM', videoStatus: 'pass' }, // Slightly ahead
    //     { id: 4, videoTime: '2:20:59 PM', videoStatus: 'fail' }, // Future timestamp
    // ];

    // const videoData = [
    //     { videoTime: new Date(new Date().getTime() - 900000).toLocaleTimeString(), videoStatus: 'pass' }, // 15 min ago
    //     { videoTime: new Date(new Date().getTime() - 600000).toLocaleTimeString(), videoStatus: 'fail' }, // 10 min ago
    //     { videoTime: new Date(new Date().getTime() - 300000).toLocaleTimeString(), videoStatus: 'pass' }, // 5 min ago
    //     { videoTime: new Date(new Date().getTime() - 120000).toLocaleTimeString(), videoStatus: 'fail' }, // 2 min ago
    //     { videoTime: new Date(new Date().getTime() - 60000).toLocaleTimeString(), videoStatus: 'pass' }, // 1 min ago
    //     { videoTime: new Date().toLocaleTimeString(), videoStatus: 'fail' }, // Now
    //   ];

    // const videoData = [
    //     { videoTime: 1, videoStatus: "pass" },
    //     { videoTime: 3, videoStatus: "fail" },
    //     { videoTime: 5, videoStatus: "pass" },
    //     { videoTime: 8, videoStatus: "fail" },
    //     { videoTime: 10, videoStatus: "pass" }
    //   ];
      
      

//    const videoData= [
//         {"videoTime": "01:30:05 PM", "videoStatus": "pass"},
//         {"videoTime": "02:35:19 PM", "videoStatus": "fail"},
//         {"videoTime": "03:30:22 PM", "videoStatus": "pass"},
//         {"videoTime": "04:33:26 PM", "videoStatus": "fail"},
//         {"videoTime": "05:57:09 PM", "videoStatus": "pass"},
//         {"videoTime": "05:58:19 PM", "videoStatus": "pass"}
// ,
// {"videoTime": "05:58:29 PM", "videoStatus": "pass"}


    
//       ]


// Sample Data for Testing
const videoData = [
    { videoTime: new Date().toLocaleTimeString(), videoStatus: 'pass' },
    { videoTime: new Date(new Date().getTime() + 5000).toLocaleTimeString(), videoStatus: 'fail' },
    { videoTime: new Date(new Date().getTime() + 10000).toLocaleTimeString(), videoStatus: 'pass' },
  ];
      




    // const videoData = [
    //     { status: "neutral" },
    //     { status: "pass" },
    //     { status: "neutral" },
    //     { status: "fail" },
    //   ];
    return (
        <>
            <div className="header-section">

                <div className="info border border-layout-border p-6 rounded-lg bg-white flex flex-col gap-6">
                    <div className="title-section ">
                        <div className="title font-semibold text-3xl text-color-gray-light">Dashboard</div>
                        <div className="inner-content text-sm font-medium text-color-gray-light">Here is a summary for {state?.url || websiteUrl}</div>

                    </div>
                    <div className="figures-sections">
                        <Test_Figures figures={figures} />
                    </div>





                    <div className="monitoring-section ">
                        <div className="title font-semibold text-3xl text-color-gray-light">Monitoring</div>
                        <div className="inner-content text-sm font-medium text-color-gray-light">You have added the following tests in monitoring</div>
                        {/* <div className="container my-6"> */}

                        <div className="info border border-layout-border p-6 rounded-lg bg-layout shadow-[0px_1px_2px_0px_#0000000D] flex flex-col gap-6 my-6">



                            {
                                formattedData.length > 0 ?

                                    <div className="grid grid-cols-1 gap-6   ">
                                        {Array.isArray(formattedData) &&
                                            formattedData.map((footprint, index) => (
                                                <div key={index} className="bg-white rounded-lg">
                                                    <VideoStatusChart  videoData={footprint?.videoData} footprintName={footprint.footprintName} />

                                                </div>
                                            ))


                                        }

                                        {/* <EcgGraph videoData={videoData} /> */}
                                        {/* <StockGraph/> */}



                                        {/* <StockGraph footprints={footprints} /> */}
                                        {/* <StockGraph videoData={videoData} /> */}
                                        {/* <VideoStatusChart  videoData={formattedData[0]?.videoData} footprintName={formattedData[0].footprintName} /> */}

                                    </div>


                                    :
                                    <div className="empty-screen my-[60px]">
                                        <Empty_Screen_Card image={monitorImage} to={`/${websiteId}/test-list`} buttonName="Go to Test lists page" mainContent="No Tasks to Monitor" subContent={<>
                                            You can add tasks from the test list <br />
                                            page to monitor here.
                                        </>} />
                                    </div>
                            }












                        </div>





                    </div>

                </div>
            </div>




        </>
    );
};

export default Dashboard;
