// import { createContext } from "react";
// import io from "socket.io-client";
// import { path } from "./config";

// export const socket = io(path, {
//   transports: ["websocket"],
//   jsonp: false,
//   forceNew: false,
// });

// export const WebSocketContext = createContext();


import { createContext } from "react";
import io from "socket.io-client";
import { path } from "./config";

console.log("Initializing WebSocket connection to:", path);

export const socket = io(path, {
  transports: ["websocket"], 
  jsonp: false, 
  forceNew: false, 
  autoConnect: true,  // Ensures connection starts automatically
});

// Logs for debugging connection issues
socket.on("connect", () => {
  console.log("✅ WebSocket Connected! Socket ID:", socket.id);
});

socket.on("connect_error", (err) => {
  console.error("❌ WebSocket Connection Error:", err.message);
});

socket.on("disconnect", (reason) => {
  console.warn("⚠️ WebSocket Disconnected. Reason:", reason);
});

socket.on("reconnect_attempt", (attemptNumber) => {
  console.log(`🔄 Reconnecting... Attempt ${attemptNumber}`);
});

socket.on("reconnect", (attemptNumber) => {
  console.log(`✅ Successfully Reconnected on Attempt ${attemptNumber}`);
});

socket.on("error", (error) => {
  console.error("❌ WebSocket Error:", error);
});

export const WebSocketContext = createContext(socket);