// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom'; // Use this if you're using React Router for navigation



// const Button = ({
//     href,
//     id,
//     text,
//     onClick,
//     to,
//     type = 'button',
//     className = '',
//     disabled = false,
//     primary = true,
//     loading,

// }) => {
//     // Conditional class name based on the primary prop
//     const buttonClass = primary
//         ? `px-5 py-3 inline-flex items-center justify-center rounded bg-primary-button font-semibold text-sm text-white hover:bg-hover transition ease-out duration-300  disabled:bg-disable-button disabled:cursor-not-allowed ${className}`
//         : `px-5 py-3 inline-flex items-center justify-center rounded bg-white text-sm text-color-gray-light hover:bg-secondary-button border border-secondary-border transition ease-out duration-300 disabled:opacity-50 disabled:bg-secondary-button disabled:text-disable-secondary font-semibold disabled:cursor-not-allowed ${className}`;

//     // If `to` is provided, render a Link
//     if (to) {
//         return (
//             <Link to={to}>
//                 <button
//                     id={id}
//                     type={type}
//                     onClick={onClick}
//                     disabled={disabled}
//                     className={buttonClass}
//                 >
//                     {text}
//                 </button>
//             </Link>
//         );
//     }


//     if (href) {
//         return (
//           <a href={href} target="_blank" rel="noopener noreferrer">
//             <button
//               id={id}
//               type={type}
//               onClick={onClick}
//               disabled={disabled}
//               className={buttonClass}
//             >
//               {text}
//             </button>
//           </a>
//         );
//       }

//     // Render a standard button
//     return (
        
//         <button
//             id={id}
//             type={type}
//             onClick={onClick}
//             disabled={disabled || loading}
//             className={buttonClass}
//         >

//             {/* {text} */}

//             {loading ? (
//                     <svg
//                         className="animate-spin h-6 w-6 mr-2 text-white"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                     >
//                         <circle
//                             className="opacity-25"
//                             cx="12"
//                             cy="12"
//                             r="10"
//                             stroke="currentColor"
//                             strokeWidth="4"
//                         ></circle>
//                         <path
//                             className="opacity-75"
//                             fill="currentColor"
//                             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
//                         ></path>
//                     </svg>
//                 ) : (
//                     <>
//                      {text}
//                     </>
//                 )}
//         </button>
//     );



    
// };


// export default Button





import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Use this if you're using React Router for navigation

const Button = ({
    href,
    id,
    text,
    onClick,
    to,
    type = 'button',
    className = '',
    disabled = false,
    primary = true,
    loading,
}) => {
    // Conditional class name based on the primary prop
    const buttonClass = primary
        ? `px-5 py-3 flex items-center justify-center rounded bg-primary-button font-semibold text-sm text-white hover:bg-hover transition ease-out duration-300 disabled:bg-disable-button disabled:cursor-not-allowed ${className}`
        : `px-5 py-3 flex items-center justify-center rounded bg-white text-sm text-color-gray-light hover:bg-secondary-button border border-secondary-border transition ease-out duration-300 disabled:opacity-50 disabled:bg-secondary-button disabled:text-disable-secondary font-semibold disabled:cursor-not-allowed ${className}`;

    // If `to` is provided, render a Link
    if (to) {
        return (
            <Link to={to}>
                <button
                    id={id}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    className={buttonClass}
                >
                    {loading ? (
                        <svg
                            className="animate-spin h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
                            ></path>
                        </svg>
                    ) : (
                        <>{text}</>
                    )}
                </button>
            </Link>
        );
    }

    if (href) {
        return (
            <a href={href} target="_blank" rel="noopener noreferrer">
                <button
                    id={id}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    className={buttonClass}
                >
                    {loading ? (
                        <svg
                            className="animate-spin h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
                            ></path>
                        </svg>
                    ) : (
                        <>{text}</>
                    )}
                </button>
            </a>
        );
    }

    // Render a standard button
    return (
        <button
            id={id}
            type={type}
            onClick={onClick}
            disabled={disabled || loading}
            className={buttonClass}
        >
            {loading ? (
                <svg
                    className="animate-spin h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
                    ></path>
                </svg>
            ) : (
                <>{text}</>
            )}
        </button>
    );
};

Button.propTypes = {
    href: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    primary: PropTypes.bool,
    loading: PropTypes.bool,
};

export default Button;
