


import React, { useEffect, useRef, useState ,useContext} from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config';
import Button from '../Button/Button';
import { WebSocketContext } from "../../websocket";

const Sidebar = ({ isExtensionInstalled }) => {
  const [websites, setWebsites] = useState([]);
  const [copy, setCopy] = useState("copy");
  const location = useLocation();
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const { websiteId } = useParams();
  const socket = useContext(WebSocketContext);


  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleAddWebsite = () => {
    localStorage.removeItem("latestWebsiteId")
    localStorage.removeItem("latestWebsiteUrl")
    setIsDropdownOpen(false)
    navigate("/addWebsite")
  }



  //socket logout feature.
  function logoutSocketEvent() {
    socket.emit("disconnectt", userId);
  }













  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("Token");
    localStorage.removeItem("isAuth");
    localStorage.removeItem('selectedWebsiteId');
    logoutSocketEvent();
    navigate("/signIn");
  };



  const handleCopy = () => {
    navigator.clipboard
      .writeText("163.172.164.206")
      .then(() => {
        setCopy("copied");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };


  const fetchWebsites = async () => {
    try {
      const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
      console.log("response from fetchwebsite", response.data.websites);
      setWebsites(response.data.websites);

      // Retrieve the last selected website ID from local storage
      const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
      if (storedWebsiteId) {
        // Find and set the previously selected website
        const storedWebsite = response.data.websites.find(
          (website) => website._id === storedWebsiteId
        );
        if (storedWebsite) {
          setSelectedWebsite(storedWebsite);
          console.log("selectedWebsite", storedWebsite._id)
        } else {
          // If not found, default to the last website
          setSelectedWebsite(response.data.websites.at(-1));
        }
      } else {
        // Default to the last website if no previous selection is stored
        setSelectedWebsite(response.data.websites.at(-1));
      }
    } catch (error) {
      console.log('Error fetching websites:', error.response);
    }
  };
  const trimUrl = (url) => {
    return url.length > 30 ? `${url.substring(0, 30)}` : url;
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleWebsiteSelect = (website) => {
    setSelectedWebsite(website);
    localStorage.setItem('selectedWebsiteId', website._id);

    setIsDropdownOpen(false);
    setActiveIndex(0); // Update active index if needed
    navigate(`/${website?._id}/dashboard`, { state: website });
  };



  useEffect(() => {
    if (userId) {
      fetchWebsites();
    }
  }, [userId, websiteId]);

  return (
    <div className="wrapper">
      <div className="flex flex-col h-full w-64 lg:w-80 xl:w-[360px]  text-white   rounded-tr-lg rounded-br-lg">  {/** fixed remove here */}

        <nav className="flex-grow gap-4  relative   ">
          <ul className=" text-color-gray-light font-bold  ">


            {websites.length > 0 &&

              // <li className={` ${isDropdownOpen ? 'border rounded-tl-lg rounded-tr-lg bg-white' : ''}`}  >
              <li className={` ${isDropdownOpen ? 'border rounded-tl-lg rounded-tr-lg bg-white' : ''}`} ref={dropdownRef} >
                <a

                  className={`header-section   cursor-pointer py-4 px-4 xl:py-4  ${!isDropdownOpen ? ' border-b' : ''}  border-link-border text-color-gray-light flex items-center justify-between relative`}
                  onClick={toggleDropdown}
                >
                  <div className="flex items-center">



                    <span className="text-base">
                      {selectedWebsite?.url ? trimUrl(selectedWebsite.url) : ''}
                    </span>
                  </div>

                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className={`fa-solid fa-caret-down cursor-pointer transition-transform ${isDropdownOpen ? 'rotate-180' : ''
                      }`}
                  // onClick={toggleDropdown}

                  >

                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="#616161" />
                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                  </svg>

                </a>

                {isDropdownOpen && (
                  <ul
                    className="absolute z-10 ml-[-1px] bg-layout w-full border rounded-bl-lg rounded-br-lg overflow-y-auto  "
                  >
                    <div
                      className="max-h-[240px] "
                      style={{
                        maxHeight: websites.length > 5 ? '200px' : 'auto',
                        overflowY: websites.length > 5 ? 'scroll' : 'visible'
                      }}
                    >
                      {websites.map((website) => (
                        <li key={website._id}
                          // className="p-3 flex justify-center items-center hover:bg-white"
                          className={`p-3 flex justify-center items-center hover:bg-white ${selectedWebsite?._id === website._id ? "bg-white text-black" : ""
                            }`}

                        >
                          <span
                            onClick={() => handleWebsiteSelect(website)}
                            className="cursor-pointer"
                          >
                            {trimUrl(website.url)}
                          </span>
                        </li>
                      ))}
                    </div>
                    <li className=" flex justify-center px-12 mb-4 mt-2 ">


                      {/* <Button text='Add Website' onClick={handleAddWebsite} /> */}

                      <button
                        onClick={handleAddWebsite}

                        className="w-full  inline-flex items-center justify-center py-2 px-6  text-base font-semibold text-white  hover:bg-hover focus:outline-none bg-[#111322] rounded-lg border
                    "
                      >
                        Add Website

                      </button>
                    </li>
                  </ul>
                )}
              </li>


            }
            <Link to={`/${websiteId}/dashboard`}>
              <li >
                <a
                  className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 border-link-border 
                     ${location.pathname === `/${websiteId}/dashboard`
                      ? 'bg-white  '
                      : 'hover:bg-white'
                    }`}
                >








                  <span
                    className=" cursor-pointer text-base "
                    onClick={() => setActiveIndex(2)}
                  >
                    Dashboard
                  </span>


                </a>
              </li>
            </Link>


            <Link to={`/${selectedWebsite?._id}/test-list`} >
              <li >
                <a
                  className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 border-link-border 
                     ${location.pathname === `/${selectedWebsite?._id}/test-list`
                      ? 'bg-white  '
                      : 'hover:bg-white'
                    }`}
                >






                  <span
                    className=" cursor-pointer text-base"
                    onClick={() => setActiveIndex(3)}
                  >
                    Tests
                  </span>




                </a>
              </li>
            </Link>

            <Link to="/setting/account">
              <li >
                <a
                  className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 border-link-border 
                      ${location.pathname === "/setting/account"
                      ? 'bg-white  '
                      : 'hover:bg-white'
                    }`}
                >







                  <span
                    className=" cursor-pointer text-base"
                    onClick={() => setActiveIndex(4)}
                  >
                    Settings
                  </span>

                </a>
              </li>
            </Link>

            <li>
              <a className="flex items-center  py-4 px-4 xl:py-4 border-b border-b-1 border-link-border   hover:bg-white  cursor-pointer">

                <span className="cursor-pointer text-base " onClick={handleLogout}>
                  Logout
                </span>
              </a>
            </li>
          </ul>
        </nav>

      </div>

    </div>
  );
};

export default Sidebar;