import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Use this if you're using React Router for navigation

const AuthButton = ({
    id,
    text,
    icon,
    onClick,
    type = 'submit',
    disabled = false,
    loading
}) => {

    // Render a standard button
    return (
        <div className=''>
            <button
                id={id}
                type={type}
                onClick={onClick}
                disabled={disabled || loading}
                className={`w-full  inline-flex items-center justify-center py-3 px-6  text-base font-semibold text-white  hover:bg-hover focus:outline-none bg-[#111322] rounded-lg border${loading ? "cursor-not-allowed opacity-80" : ""
                    } `}
            >

                {loading ? (
                    <svg
                        className="animate-spin h-6 w-6 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
                        ></path>
                    </svg>
                ) : (
                    <>
                        {icon} {text}
                    </>
                )}
            </button>
        </div>

    );
};

// Prop Types for better validation
AuthButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default AuthButton;

