import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./app/store";
import { WebSocketContext, socket } from "./websocket.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WebSocketContext.Provider value={socket}>

    <GoogleOAuthProvider clientId="480815347716-breenplbnlsk80fvnkgjh9n0bh4jh003.apps.googleusercontent.com">
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>

      </Provider>
    </GoogleOAuthProvider>
  </WebSocketContext.Provider >
);

