import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Test_Video_Table from '../Table/Test_Video_Table';
import Viral_Post_Table from '../Table/Viral_Post_table';

const Test_Status = ({ footprintName }) => {

    // const [filter, setFilter] = useState([]);
    const [searchText, setSearchText] = useState("");
    const { websiteId, footprintId } = useParams();
    const [data, setData] = useState({
        successCount: "",
        failureCount: "",

    })

    const userId = localStorage.getItem("userId")

    const handleStatusCount = (payload) => {
        console.log("status ke ander", payload)
        setData(payload);
    }

    const [filter, setFilter] = useState("All");


    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    };
    const handleFilterClick = (status) => {
        setFilter(status);
    };
    return (
        <>
            <div className="header-section">



                <div className="setup-guide">
                    <div className="inner-setup-guide text-color-gray-light flex flex-col  gap-6">
                        <div className="top-section">
                            <div className="inner-section flex justify-between ">
                                <div className="filter-section">
                                    <div className="filter-items">
                                        <div className="items flex justify-between bg-layout border border-layout-border font-medium text-sm items-center gap-2.5  rounded-md p-[5px] text-filter-color">
                                            <div
                                                className={`item-1 rounded-[4px] px-3 py-1.5  cursor-pointer transition ease-out duration-300 ${filter == "All" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light font-semibold " : ""
                                                    }`}
                                                onClick={() => handleFilterClick("All")}
                                            >
                                                All
                                            </div>
                                            <div
                                                className={`item-2 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "Pass" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                                    }`}
                                                onClick={() => handleFilterClick("Pass")}
                                            >
                                                Pass ({data?.successCount})
                                            </div>
                                            <div
                                                className={`item-3 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "Fail" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                                    }`}
                                                onClick={() => handleFilterClick("Fail")}
                                            >
                                                Fail ({data?.failureCount})
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="search-bar">
                                    <div className="relative flex items-center py-[11px] px-6 border border-link-border rounded-full">
                                        <input
                                            type="text"
                                            placeholder="Search Run#"
                                            value={searchText}
                                            onChange={handleInputChange}
                                            className="w-full  pr-10 font-normal text-color-gray-light bg-transparent border-none text-sm tracking-wider focus:outline-none  placeholder-disable-secondary"
                                        />
                                        <svg
                                            className="absolute right-5 top-1/2 transform -translate-y-1/2"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13 13L19 19M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15Z"
                                                stroke="#DCDFEA"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="list-section ">
                            <div className="table-list  ">
                                <div className="flex-grow">
                                    {userId === "66d962c5cf35e9afa861ef30" ? 
                                    <Viral_Post_Table
                                        websiteId={websiteId} footprintId={footprintId} filter={filter} handleCount={handleStatusCount} searchText={searchText} footprintName={footprintName}

                                    /> :
                                        <Test_Video_Table websiteId={websiteId} footprintId={footprintId} filter={filter} handleCount={handleStatusCount} searchText={searchText} footprintName={footprintName}
                                        />}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Test_Status

